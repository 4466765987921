import constant from "../helper/constant";
import { getUUID } from "../helper/helper";
import http, { askMeHttp, crewBoHttp } from "./../helper/http";

export const getTemplateList = (params) =>
  http.get(constant.API.AI_MODEL.GET_AI_TEMPLATES, { params });

export const createNewTemplateWithData = (payload) =>
  http.post(`${constant.API.AI_MODEL.GET_AI_TEMPLATES}/upload`, payload);

export const createNewTemplateWithoutData = (payload) =>
  http.post(`${constant.API.AI_MODEL.GET_AI_TEMPLATES}`, payload);

export const updateExistingTemplate = (payload) =>
  http.put(`${constant.API.AI_MODEL.GET_AI_TEMPLATES}/${payload.id}`, payload);

export const deleteDraftTemplate = (id) =>
  http.delete(`${constant.API.AI_MODEL.GET_AI_TEMPLATES}/${id}`);

export const getModelList = (id) =>
  http.get(`${constant.API.AI_MODEL.GET_AI_TEMPLATES}/${id}`);

export const getDepartmentList = (params) =>
  http.get(constant.API.AI_MODEL.GET_DEPARTMENTS, { params });

export const getTaskListForDepartment = (params) =>
  http.get(constant.API.AI_MODEL.GET_TASKS, { params });

export const getAIValueData = (payload) =>
  http.post(constant.API.AI_MODEL.GET_AI_VALUE, payload);

export const getAIValueByModel = (id) =>
  http.get(constant.API.AI_MODEL.GET_AI_VALUE_PROFIT.replace("{id}", id));

export const getAIRoadmapData = (id) =>
  http.get(constant.API.AI_MODEL.GET_ROAD_MAP.replace("{id}", id));

export const getModelDetailForId = (id) =>
  http.get(constant.API.AI_MODEL.GET_MODEL_DETAILS.replace("{id}", id));

export const getAIDataWorkflows = () =>
  http.get(constant.API.AI_MODEL.GET_AI_DATA_WORKFLOW);

export const getAIDataTargets = () =>
  http.get(constant.API.AI_MODEL.GET_AI_DATA_TARGET);

export const getAIDataFeatures = () =>
  http.get(constant.API.AI_MODEL.GET_AI_DATA_FEATURE);

export const getAIDataUploadStatus = (id) =>
  http.get(constant.API.AI_MODEL.GET_AI_DATA_UPLOAD_STATUS.replace("{id}", id));

export const getAIPredictLookup = (id) =>
  http.get(constant.API.AI_MODEL.GET_AI_PREDICT_LOOKUP.replace("{id}", id));

export const triggerModelProcess = (id, payload) =>
  http.post(`${constant.API.AI_MODEL.TRIGGER_MODEL_PROCESS}/${id}`, payload);

export const getTrainingModel = (id) =>
  http.get(`${constant.API.AI_MODEL.TRANING_MODEL}/${id}`);

export const getSocialMediaModel = (id) =>
  http.get(`${constant.API.AI_MODEL.SOCIAL_MEDIA_MODEL}/${id}`);

export const saveTrainingModel = (payload, isUpdate) => {
  if (isUpdate) updateTrainingModel(payload);
  return http.post(constant.API.AI_MODEL.TRANING_MODEL, payload);
};

export const updateTrainingModel = (payload) =>
  http.put(`${constant.API.AI_MODEL.TRANING_MODEL}/${payload.id}`, payload);

export const generateProcessor = () =>
  http.get(
    `${constant.API.AI_MODEL.DOCUMENT_AI_PREDICTION}/createprocessor/${getUUID()}`
  );

export const saveSocialMediaModel = (payload, isUpdate) => {
  if (isUpdate) return updateSocialMediaModel(payload);
  return http.post(constant.API.AI_MODEL.SOCIAL_MEDIA_MODEL, payload);
};

export const updateSocialMediaModel = (payload) =>
  http.put(
    `${constant.API.AI_MODEL.SOCIAL_MEDIA_MODEL}/${payload.id}`,
    payload
  );

export const getSocialMediaSentiment = (payload) => {
  const { templateId, taskId } = payload;
  return http.get(
    `${constant.API.AI_MODEL.SOCIAL_MEDIA_PREDICTION_SENTIMENT}/${templateId}/${taskId}`
  );
};

export const getSocialMediaReviews = (payload) => {
  const { templateId, taskId, isNegative } = payload;
  const params = isNegative ? { type: "lowest_rating" } : {};
  return http.get(
    `${constant.API.AI_MODEL.SOCIAL_MEDIA_PREDICTION_REVIEW}/${templateId}/${taskId}/${1}`,
    { params }
  );
};

export const getSocialMediaQuery = (payload) => {
  const { templateId, taskId } = payload;
  return http.post(
    `${constant.API.AI_MODEL.SOCIAL_MEDIA_PREDICTION_QUERY}/${templateId}/${taskId}`,
    payload
  );
};

export const createSocialAds = (payload, taskId) =>
  http.post(
    `${constant.API.AI_MODEL.SOCIAL_MEDIA_CREATE_AD}/${taskId}`,
    payload
  );

export const createSocialImages = (payload, taskId) =>
  http.post(
    `${constant.API.AI_MODEL.SOCIAL_MEDIA_CREATE_IMAGES}/${taskId}`,
    payload
  );

export const getRegressionData = (templateId, taskId) =>
  http.get(
    `${constant.API.AI_MODEL.REGRESSION_GET_DATA}/${templateId}/${taskId}`
  );

export const triggerEmail = (templateId, payload) =>
  http.post(`${constant.API.AI_MODEL.SEND_EMAIL}/${templateId}`, payload);

export const postRegressionData = (templateId, taskId, payload) =>
  http.post(
    `${constant.API.AI_MODEL.REGRESSION_GET_DATA}/${templateId}/${taskId}`,
    payload
  );

export const uploadRegressionData = (templateId, taskId, payload) =>
  http.post(
    `${constant.API.AI_MODEL.REGRESSION_UPLOAD_DATA}/${templateId}/${taskId}`,
    payload
  );

export const postImagePrediction = (templateId, taskId, payload) =>
  http.post(
    `${constant.API.AI_MODEL.REGRESSION_GET_DATA}/${templateId}/${taskId}`,
    payload
  );

export const getImagePrediction = (templateId, taskId) =>
  http.get(
    `${constant.API.AI_MODEL.REGRESSION_GET_DATA}/${templateId}/${taskId}`
  );

export const getTextFromImage = (payload) =>
  http.post(`${constant.API.AI_MODEL.EXTRACT_TEXT_FROM_IMAGE}`, payload);

export const submitMultiUploadRequest = (payload) =>
  http.post(`${constant.API.AI_MODEL.MULTI_UPLOAD_REQUEST}`, payload);

export const getDocumentAIPrediction = (templateId, taskId) =>
  http.get(
    `${constant.API.AI_MODEL.DOCUMENT_AI_PREDICTION}/${templateId}/${taskId}`
  );

export const postDocumentPrediction = (templateId, taskId, payload) =>
  http.post(
    `${constant.API.AI_MODEL.DOCUMENT_AI_PREDICTION}/upload/${templateId}/${taskId}`,
    payload
  );

export const updateDocumentPrediction = (templateId, id, payload) =>
  http.put(
    `${constant.API.AI_MODEL.DOCUMENT_AI_PREDICTION}/${templateId}/${id}`,
    payload
  );

export const getKnowledgeAIPrediction = (templateId, taskId) =>
  http.get(
    `${constant.API.AI_MODEL.KNOWLEDGE_AI_PREDICTION}/${templateId}/${taskId}`
  );

export const requestKnowledgePrediction = (templateId, taskId, payload) =>
  http.post(
    `${constant.API.AI_MODEL.KNOWLEDGE_AI_PREDICTION}/${templateId}/${taskId}`,
    payload
  );

export const requestAutomatedTargets = (payload) =>
  http.post(
    `${constant.API.AI_MODEL.TRANING_MODEL}/autopopulatefields`,
    payload
  );

export const getEvaluateAIData = (templateId, id) =>
  http.get(
    `${constant.API.AI_MODEL.REGRESSION_GET_DATA}/146/5a5bd76f-588d-4a57-9711-dfa9b6d40ed3/evaluate`
  );

export const getDynamicChartTypes = (templateId, id) =>
  http.get(
    `${constant.API.AI_MODEL.REGRESSION_GET_DATA}/${templateId}/${id}/explain`
  );

export const getPredictionExplainData = (templateId, id) =>
  http.get(
    `${constant.API.AI_MODEL.REGRESSION_GET_DATA}/${templateId}/${id}/explaindata`
  );

export const getTimeSeriesPrediction = (templateId, id) =>
  http.get(
    `${constant.API.AI_MODEL.REGRESSION_GET_DATA}/batch/${templateId}/${id}`
  );

export const postTimeSeriesPrediction = (templateId, id, payload) =>
  http.post(
    `${constant.API.AI_MODEL.REGRESSION_GET_DATA}/batch/${templateId}/${id}`,
    payload
  );

//Business overview
export const createBusinessOverview = (payload) =>
  crewBoHttp.post(`${constant.API.AI_MODEL.GENERATE_BO_REPORT}`, payload);

export const getBusinessOverview = (params) =>
  http.get(`${constant.API.AUTH.ORG}/orginfo`, { params });

export const createDynamicWorkflow = (payload) =>
  http.post(`${constant.API.AI_MODEL.GET_AI_TEMPLATES}/autocreate`, payload);

export const triggerOtherOrgInfo = () =>
  http.post(`${constant.API.AUTH.ORG}/orginfo_others`);

//Chatbot APIs
export const postChatboxQuestion = (payload) =>
  askMeHttp.post(`${constant.API.AI_MODEL.CHATBOT}`, payload);

export const getChatboxQuestion = () =>
  http.get(`${constant.API.AI_MODEL.ASK_ME}/getUserInputAndAgentId`);

export const getChatboxQuestionDetails = (params) =>
  http.get(`${constant.API.AI_MODEL.ASK_ME}`, { params });

export const deleteChatFile = (data) =>
  http.delete(`${constant.API.AI_MODEL.ASK_ME}`, { data });

export const uploadChatbotFile = (payload) =>
  http.post(`${constant.API.AI_MODEL.ASK_ME}`, payload);

export const getChatbotPastFiles = (params) =>
  http.get(`${constant.API.AI_MODEL.ASK_ME}/getfiles`, { params });

export const getSignedFile = (payload) =>
  http.post(`${constant.API.AI_MODEL.ASK_ME}/getsignedurl`, payload);

export const getUserList = () =>
  http.get(`${constant.API.AUTH.ORG}/findAllUsers`);

export const addUser = (payload) =>
  http.post(`${constant.API.AUTH.ORG}/createUser`, payload);

export const updateUser = (payload) =>
  http.post(`${constant.API.AUTH.ORG}/updateRole`, payload);

export const deleteUser = (payload) =>
  http.post(`${constant.API.AUTH.ORG}/deleteUser`, payload);

//ASK ME APIS
export const getTaskForQuery = (payload) =>
  http.post(`${constant.API.AI_MODEL.ASK_ME}/gettasksforquery`, payload);

export const getWorkflowForQuery = (payload) =>
  http.post(`${constant.API.AI_MODEL.ASK_ME}/getmodelforquery`, payload);

export const createAskMeWorkflow = (payload) =>
  http.post(`${constant.API.AI_MODEL.ASK_ME}/createautomatic`, payload);

export const getDepartmentSuggestedTasks = (payload) =>
  http.post(
    `${constant.API.AI_MODEL.GET_AI_TEMPLATES}/getdepartmentinfo`,
    payload
  );

export const checkForTemplateName = (name) =>
  http.get(`${constant.API.AI_MODEL.GET_AI_TEMPLATES}/uniquename/${name}`);

export const uploadChatFileWebUrl = (payload) =>
  http.get(`${constant.API.AI_MODEL.ASK_ME}`, payload);

export const getAgentTypes = () =>
  http.get(`${constant.API.AI_MODEL.AGENT_TYPE}`);

export const getAgentsAPI = () => http.get(`${constant.API.AI_MODEL.AGENT}`);

export const getAllModelsAPI = () =>
  http.get(`${constant.API.AI_MODEL.ASK_ME}/getAllApi`);

export const getAllModelsForAgentAPI = (agent) =>
  http.get(`${constant.API.AI_MODEL.AGENT}/${agent}`);

export const createAgentAPI = (payload, agent) => {
  if (agent) {
    return http.put(`${constant.API.AI_MODEL.AGENT}/${agent}`, payload);
  }
  return http.post(`${constant.API.AI_MODEL.AGENT}`, payload);
};

export const deleteAgentAPI = (id) => {
  return http.delete(`${constant.API.AI_MODEL.AGENT}/${id}`);
};

export const uploadAgentFileAPI = (payload) =>
  http.post(`${constant.API.AI_MODEL.ASK_ME}`, payload);

export const deleteAgentFileAPI = (params) =>
  http.delete(`${constant.API.AI_MODEL.ASK_ME}`, { params });

export const connectModelToAgentAPI = (payload) =>
  http.post(`${constant.API.AI_MODEL.ASK_ME}`, payload);
