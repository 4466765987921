// user.reducer.js
import { useReducer } from "react";
import * as ACTION from "./types";

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  isOrgActive: false,
  industries: [],
  selectedIndustry: null,
  userInfo: null,
  authError: null,
  isRevenueCalculated: false,
  orgInfo: { org_name: "ABC Inc" },
  tenentInfo: null,
  revenueReport: {
    revenue: null,
    totalAdditionalEBITDA: null,
    currencyType: null,
    breakDown: {
      revenue_increase: null,
      aiCost: null,
      inventory_benefit: null,
      manpower_benefit: null,
    },
    roi: null,
    threemonth_deplay_cost: null,
    payback_period: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.UPDATE_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };

    case ACTION.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userInfo: action.payload,
        authError: null,
      };
    case ACTION.UPDATE_ORG_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case ACTION.GET_INDUSTRIES:
      return {
        ...state,
        industries: action.payload,
      };
    case ACTION.USER_LOGIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
        authError: action.payload,
      };
    case ACTION.SET_SELECTED_INDUSTRY:
      return {
        ...state,
        selectedIndustry: action.payload,
      };
    case ACTION.USER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
      };
    case ACTION.CALCULATE_UNSIGNED_REVENUE_SUCCESS:
      return {
        ...state,
        revenueReport: action.payload,
        isRevenueCalculated: true,
      };
    case ACTION.GET_UNSIGNED_REPORT_BY_MAIL_SUCCESS:
      return {
        ...state,
        orgInfo: action.payload,
      };
    case ACTION.CLEAR_UNSIGNED_REVENUE:
      return {
        ...state,
        revenueReport: null,
      };
    case ACTION.CREATE_ORG_SUCCESS:
      return {
        ...state,
        tenentInfo: action.payload,
      };
    case ACTION.UPDATE_REVENUE_REPORT:
      return {
        ...state,
        revenueReport: action.payload,
      };

    default:
      return state;
  }
};

const useAuthReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    dispatch,
  };
};

export default useAuthReducer;
