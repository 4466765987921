import React, { useEffect, useRef, useState } from "react";
import images from "../../helper/images";
import { Link } from "react-router-dom";
import {
  calculatePercentage,
  formatDate,
  formatToCurrency,
  sumOfObject,
} from "../../helper/helper";
import { useAuthContext } from "../../context/auth.context";
import { getSolutionList } from "../../service/auth.service";
import constant from "../../helper/constant";
import PredDonutChart from "../../components/common/charts/PredDonutChart";
import { useScreenSizeContext } from "../../context/mobile-layout.context";

const UnsignedReportPage = () => {
  const { revenueReport, orgInfo, selectedIndustry, industries } =
    useAuthContext();
  const reportRef = useRef(null);
  const [solutionList, setSolutionList] = useState([]);
  const { isMobileScreen } = useScreenSizeContext();

  useEffect(() => {
    if (selectedIndustry) {
      getSolutionList(selectedIndustry)
        .then((list) => setSolutionList(list))
        .catch((ex) => {})
        .finally(() => {});
    }
  }, [selectedIndustry]);

  const renderDonutChart = () => {
    const report = getRevenueReport();
    if (report) return <PredDonutChart data={report} center={renderCenter} />;
  };

  const getMaterialCostIncrease = () =>
    revenueReport.before.inventory_benefit -
    revenueReport.after.inventory_benefit;

  const getRevenueIncrease = () =>
    revenueReport.after.revenue_increase -
    revenueReport.before.revenue_increase;

  const getManpowerBenefit = () =>
    revenueReport.before.manpower_benefit -
    revenueReport.after.manpower_benefit;

  const getOverAllExpenses = () =>
    Number(revenueReport.before.revenue_increase) +
    Number(revenueReport.before.manpower_benefit) +
    Number(revenueReport.before.inventory_benefit);

  const renderRevenueTable = () => {
    const percentage = calculatePercentage(
      revenueReport.after.revenue_increase -
        revenueReport.before.revenue_increase,
      revenueReport.before.revenue_increase
    ).toFixed(2);

    return (
      <section className="pb-5 revenuetblsec">
        <div className="container">
          <table className="table table-borderless border text-center">
            <thead>
              <tr className="border-bottom">
                <th scope="col" className="border-right"></th>
                <th scope="col" className="border-right">
                  Before
                </th>
                <th scope="col" className="border-right">
                  After
                </th>
                <th scope="col" className="border-right">
                  Change
                </th>
                <th scope="col" className="border-right">
                  Change (%)
                </th>
                <th scope="col" className="">
                  Additional Profit
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="border-right text-left">
                  Revenue Increase
                </th>
                <td className="border-right">
                  {formatToCurrency(
                    revenueReport.before.revenue_increase * constant.Million
                  )}
                </td>
                <td className="border-right">
                  {formatToCurrency(
                    revenueReport.after.revenue_increase * constant.Million
                  )}
                </td>
                <td className="border-right">
                  {formatToCurrency(getRevenueIncrease() * constant.Million)}
                </td>
                <td className="border-right">{percentage}</td>
                <td className="border-right">
                  {formatToCurrency(getRevenueIncrease() * constant.Million)}
                </td>
              </tr>
              <tr>
                <th scope="row" className="border-right text-left">
                  Manpower Efficiencies
                </th>
                <td className="border-right">
                  {formatToCurrency(
                    revenueReport.before.manpower_benefit * constant.Million
                  )}
                </td>
                <td className="border-right">
                  {formatToCurrency(
                    revenueReport.after.manpower_benefit * constant.Million
                  )}
                </td>
                <td className="border-right">
                  {formatToCurrency(getManpowerBenefit() * constant.Million)}
                </td>
                <td className="border-right">
                  {calculatePercentage(
                    revenueReport.before.manpower_benefit -
                      revenueReport.after.manpower_benefit,
                    revenueReport.before.manpower_benefit
                  ).toFixed(2)}
                </td>
                <td className="border-right">
                  {formatToCurrency(getManpowerBenefit() * constant.Million)}
                </td>
              </tr>
              <tr>
                <th scope="row" className="border-right text-left">
                  Material Cost Reduction
                </th>
                <td colspan="" className="border-right">
                  {formatToCurrency(
                    revenueReport.before.inventory_benefit * constant.Million
                  )}
                </td>
                <td className="border-right">
                  {formatToCurrency(
                    revenueReport.after.inventory_benefit * constant.Million
                  )}
                </td>
                <td className="border-right">
                  {formatToCurrency(
                    getMaterialCostIncrease() * constant.Million
                  )}
                </td>
                <td className="border-right">
                  {calculatePercentage(
                    revenueReport.before.inventory_benefit -
                      revenueReport.after.inventory_benefit,
                    revenueReport.before.inventory_benefit
                  ).toFixed(2)}
                </td>
                <td className="border-right">
                  {formatToCurrency(
                    getMaterialCostIncrease() * constant.Million
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    );
  };

  const getRevenueReport = () => {
    return [
      {
        name: "revenue Increase",
        value: getRevenueIncrease() * constant.Million,
      },
      {
        name: "material cost reduction",
        value: getMaterialCostIncrease() * constant.Million,
      },
      {
        name: "manpower efficiencies",
        value: getManpowerBenefit() * constant.Million,
      },
    ];
  };

  const getAdditionalProfit = () => {
    return (
      (getRevenueIncrease() +
        getManpowerBenefit() +
        getMaterialCostIncrease()) *
      constant.Million
    );
  };
  const renderCenter = (cx, cy) => {
    console.log(getOverAllExpenses());
    const additionalProfit = getAdditionalProfit();
    if (additionalProfit) {
      const percentage = calculatePercentage(
        additionalProfit,
        getOverAllExpenses() * constant.Million
      ).toFixed(2);
      const dimensions = isMobileScreen
        ? constant.donutDimension.mobile
        : constant.donutDimension.desktop;

      return (
        <>
          <text
            x={cx - dimensions.centerXOffset}
            y={cy - dimensions.centerYOffset}
            dy={8}
            textAnchor="middle"
            fill="#000000"
            fontSize={isMobileScreen ? 20 : 24}
            fontWeight="bold"
          >
            <tspan>Additional Profit*</tspan>
          </text>
          <text
            x={cx}
            y={cy + (isMobileScreen ? 5 : 15)}
            dy={8}
            textAnchor="middle"
            fill="#217CE8"
            fontSize={isMobileScreen ? 20 : 28}
            fontWeight="bold"
          >
            {formatToCurrency(additionalProfit)}
          </text>
          <text
            x={cx}
            y={cy + (isMobileScreen ? 30 : 35)}
            dy={8}
            textAnchor="middle"
            fill="#217CE8"
            fontSize={18}
            fontWeight="bold"
          >
            {`(${percentage}%)`}
          </text>
        </>
      );
    }
  };

  const renderAiSolutions = () => {
    if (solutionList && solutionList.length) {
      return solutionList.map((item) => {
        const { id, feature, icon_url } = item;
        return (
          <li
            key={`feature-${id}`}
            className="d-flex align-items-baseline capitalize"
            style={{ "--img": `url(${icon_url})` }}
          >
            {feature}
          </li>
        );
      });
    }
  };

  const renderAiTimeline = () => {
    return (
      <section className="resultweeks bg-light">
        <div className="container position-relative">
          <h3 className="text-center mb-5 pb-5">Get results in weeks</h3>
          <div className="position-relative mt-5">
            <img src={images.unsigned.imgTimeline} alt="" className="w-100" />
            <div className="timelinebox timelinebox1">
              <div className="bold h4 text-blue">AI Training</div>
              <p>
                Empower your team to succeed with AI. Our training programs
                provide the knowledge and skills your team needs to demystify AI
                and start using it to drive growth.
              </p>
              <div className="bold text-secondary h4">2 Hours</div>
            </div>
            <div className="timelinebox timelinebox2">
              <div className="bold text-secondary h4">4 Hours</div>
              <p>
                Transform your business with AI. Our AI platform helps you to
                develop a custom AI strategy that aligns with your business
                goals and drives results.
              </p>
              <div className="bold h4 text-blue">AI Strategy</div>
            </div>
            <div className="timelinebox timelinebox3">
              <div className="bold h4 text-blue">AI Transformation</div>
              <p>
                Maximize the impact of AI on your business. Our team of AI
                experts works with you to implement your strategy and transform
                your operations for optimal results.
              </p>
              <div className="bold text-secondary h4">1-3 Days</div>
            </div>
            <div className="timelinebox timelinebox4">
              <div className="bold text-secondary h4">As Requested</div>
              <p>
                Integrate AI seamlessly into your business operations. AI
                Integration ensures that the AI models are connected across all
                functions, delivering powerful insights and results.
              </p>
              <div className="bold h4 text-blue">AI Integration</div>
            </div>
          </div>
          <small className="usr-timeline-note-text">
            Note: Timeline for sample industry with sample data. Actual timeline
            may vary depending on the data availability.
          </small>
        </div>
      </section>
    );
  };

  const render = () => {
    const industry = industries?.filter((item) => item.id == selectedIndustry);
    const additionalProfit = getAdditionalProfit();
    return (
      <>
        <div id="companyDetailedReport" ref={reportRef}>
          <header className="header2 bg-blue border-bottom px-3 py-4 ">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-white">
                  <h3 className=" text-white ">
                    Additional Profit Report for{" "}
                    <b className="capitalize">{orgInfo.org_name}</b>.{" "}
                  </h3>
                  <div>{formatDate(null, "DD.MM.YYYY")}</div>
                </div>
                <img src={images.unsigned.imgLogo2} alt="" />
              </div>
            </div>
          </header>

          <section className="position-relative">
            <div>
              <img src={images.unsigned.imgBanner2} alt="" className="w-100" />
            </div>
            <div
              className={`bannertext text-white bg-blue text-center ${isMobileScreen ? "bannertext-mob py-3" : "px-5 py-4"}`}
            >
              <div className="h5">Additional Profit*</div>
              <div className="h1 mb-0 text-white bold">
                {formatToCurrency(additionalProfit)}
              </div>
            </div>
          </section>

          <section
            className={`py-5 border-bottom ${isMobileScreen ? "" : "mb-5"}`}
          >
            <div className="container">
              <div
                className={`mx-auto d-flex align-items-center text-center ${isMobileScreen ? "resultbox-mob" : "resultbox"}`}
              >
                <div className={`${isMobileScreen ? "py-1" : "py-4"}`}>
                  <div>3-Year ROI</div>
                  <h4 className="h2 mt-2 mb-0">
                    {revenueReport.roi
                      ? revenueReport.roi?.toFixed(2.2) + "%"
                      : "-"}
                  </h4>
                </div>
                <div className={`${isMobileScreen ? "py-1" : "py-4"}`}>
                  <div>3-Month Delay Cost</div>
                  <h4 className="h2 mt-2 mb-0">
                    {revenueReport.threemonth_deplay_cost
                      ? formatToCurrency(
                          revenueReport.threemonth_deplay_cost *
                            constant.Million
                        )
                      : "-"}
                  </h4>
                </div>
                <div className={`${isMobileScreen ? "py-1" : "py-4"}`}>
                  <div>Payback Period</div>
                  <h4 className="h2 mt-2 mb-0">
                    {revenueReport.payback_period
                      ? Math.ceil(revenueReport.payback_period) + " Mos"
                      : "-"}
                  </h4>
                </div>
              </div>
              {renderDonutChart()}
              {!isMobileScreen && renderRevenueTable()}
            </div>
          </section>
          {solutionList && solutionList.length > 0 && (
            <section className="py-5 text-center border-bottom">
              <div className="container">
                <h3 className="text-center mb-4">
                  AI Solutions for
                  {industry.length && (
                    <span className="text-blue capitalize">
                      {" "}
                      {industry[0].industry_name}
                    </span>
                  )}
                </h3>
                <div className="row">
                  <div className="col-md-11 mx-auto">
                    <ul className="resullist">{renderAiSolutions()}</ul>
                  </div>
                </div>
                <div className="pt-5 mt-4">
                  <h2 className="bold mb-4 text-blue">Avtar AI</h2>
                  <p className="h4 lineheight10">
                    {" "}
                    is our AI driven application to address Industry-specific
                    problems and make proper predictions or recommendations to
                    solve them
                  </p>
                </div>
              </div>
            </section>
          )}
          {!isMobileScreen && renderAiTimeline()}
          <section className="py-5 mb-5 border-top bg-light">
            <div className="container">
              <h3 className="text-center text-blue mb-4">
                Preview of <span className="bold">Avtar AI </span>
              </h3>
              <div>
                <img
                  src={images.unsigned.imgDashboard}
                  alt=""
                  className="w-100"
                />
              </div>
              <div className="mt-5 text-center d-flex justify-content-center align-items-center automatebtn">
                <a
                  className="btn1next mx-2 btn1radiuos bold"
                  href={constant.UNSIGNED.BOOK_DEMO}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Book a demo{" "}
                </a>
                <Link className="btn1next mx-2 btn1radiuos bold" to="/login">
                  Sign Up{" "}
                </Link>
              </div>
            </div>
          </section>

          <footer className="bg-blue py-2">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <a
                    className="text-white"
                    href="tel:416.574.3800"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    416.574.3800
                  </a>
                </div>
                <div className="col-md-4 text-center">
                  <a
                    className="text-white"
                    href="mailto:hello@incorporation.ai"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    hello@incorporation.ai
                  </a>
                </div>
                <div className="col-md-4 text-right">
                  <a
                    className="text-white"
                    href="https://www.incorp.ai"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.incorp.ai
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </>
    );
  };

  return render();
};

export default UnsignedReportPage;
