import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAIModelContext } from "../../../context/ai-model.context";
import RightSidemenu from "../../common/RightSidemenu";
import CustomLoader from "../../common/CustomLoader";
import DepartmentForm from "./DepartmentForm";
import ModelCreator from "../ModelCreator";
import constant from "../../../helper/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import Timeline from "../../common/Timeline";
import { getAIValueByModel } from "./../../../service/ai-model.service";

const AIWorkflow = () => {
  const {
    showRightMenu,
    getModels,
    setActiveTemplateId,
    showModels,
    isShowModel,
    template_name,
    updateTemplateName,
    updateTemplate,
    models,
    isOverviewFirstTime,
  } = useAIModelContext();

  const params = useParams();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params.id) {
      setActiveTemplateId(params.id);
      getModels(params.id);
    }
  }, [params.id]);

  const onShowModels = () => showModels();

  const onModelNameSave = (e) => {
    e.preventDefault();
    updateTemplate(params.id);
    setEdit(false);
  };

  useEffect(() => {}, [isOverviewFirstTime]);

  const renderModelName = () => {
    return (
      <div className="ai-model-name-input align-items-center">
        <div className="h6 m-2">Workflow Title : </div>
        {!edit && (
          <>
            <strong>{template_name}</strong>
            <Link
              onClick={(e) => {
                e.preventDefault();
                setEdit(true);
              }}
            >
              <FontAwesomeIcon icon={faEdit} color="gray" size="lg" />
            </Link>
          </>
        )}
        {edit && (
          <>
            <div className="h6 m-2">Workflow: </div>
            <input
              type="text"
              className="form-control"
              placeholder="Model name"
              value={template_name}
              onChange={(e) => updateTemplateName(e.target.value)}
            />
            <Link href="" onClick={onModelNameSave} className="px-2">
              <FontAwesomeIcon
                className="ml-2"
                icon={faCheckCircle}
                color="green"
                fontWeight="bold"
                size="lg"
              />
            </Link>
          </>
        )}
      </div>
    );
  };
  const onCalculate = () => {
    setLoading(true);
    getAIValueByModel(params.id)
      .then(() => navigate(`/${constant.PAGES.HOME.AI_VALUE}/${params.id}`))
      .catch(() => setLoading(false));
  };

  const render = () => {
    const disableNext =
      models.filter((item) => !item.tasks || item.tasks?.length === 0).length >
      0;
    return (
      <div className="row mx-0">
        {loading && <CustomLoader full />}
        <div
          className={` px-md-0 ${(!isShowModel && showRightMenu) || isShowModel ? "col-md-10" : "col-md-12"}`}
        >
          <div className="p-5">
            <div className="col-xs-12 py-3">{renderModelName()}</div>
            <div className="row">
              <div className="col-xs-12 col-md-8 m-auto">
                <Timeline />
              </div>
            </div>
            <div className="p-3 text-center">
              <ModelCreator />
              <div className="d-flex my-3 flex-column gap-1 align-items-center">
                {disableNext && (
                  <small className="font-weight-bold">
                    At least one task should be added per department
                  </small>
                )}
                {!isShowModel && (
                  <button
                    className="btn1 btn radious-0  w-25 mt-2 f-13 text-uppercase py-2 font15fontweight600"
                    disabled={disableNext}
                    onClick={onShowModels}
                  >
                    Implement AI Mode
                  </button>
                )}
                {isShowModel && (
                  <button
                    className="btn1 btn radious-0  w-25 mt-2 f-13 text-uppercase py-2 font15fontweight600"
                    onClick={onCalculate}
                  >
                    Calculate
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isShowModel && showRightMenu && (
          <RightSidemenu>
            <DepartmentForm />
          </RightSidemenu>
        )}
      </div>
    );
  };

  return render();
};

export default AIWorkflow;
