import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import CusomtLoader from "./../../common/CustomLoader";
import { useAppContext } from "./../../../context/app.context";
import {
  createAgentAPI,
  getAllModelsAPI,
  uploadAgentFileAPI,
  getAgentTypes,
  getAllModelsForAgentAPI,
  getAgentsAPI,
  deleteAgentFileAPI,
  deleteAgentAPI,
} from "../../../service/ai-model.service";
import AgentModelCheckbox from "./AgentModelCheckbox";
import constant, { ToastType, FILE_TYPE } from "../../../helper/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faFileAlt,
  faPencil,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import CustomDropdown from "./../../common/CustomDropdown";
import { useAIAskMeContext } from "./../../../context/ai-ask-me.context";

const CreateAgent = () => {
  const params = useParams();
  const [agentTypes, setAgentTypes] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [agentFiles, setAgentFiles] = useState([]);
  const [modelList, setModelList] = useState([]);
  const { showAlert, updateLoader, isLoading } = useAppContext();
  const fileRef = useRef();
  const [fileUpload, setFileUpload] = useState(null);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const { updateShowModal, updateActiveAgent } = useAIAskMeContext();

  useEffect(() => {
    getAgentInputData();
  }, [params.agent]);

  useEffect(() => {
    if (selectedAgent) {
      getSelectedModels();
    }
  }, [selectedAgent, params.agent]);

  const getAgentInputData = () => {
    Promise.all([getAgentTypes(), getAgentsAPI(), getAllModelsAPI()])
      .then(([types, agents, models]) => {
        if (params.agent) {
          setSelectedAgent(params.agent);
          setShowMoreInfo(true);
        }
        setAgentList(agents);
        const existingList = agents.map((item) => item.id);
        const list = types.filter((item) => !existingList.includes(item.id));
        setAgentTypes(list);

        if (models && models.length) {
          const data = models.filter((m) => m.cloud_run_url != "0");
          setModelList(data);
        }
      })
      .catch((ex) => showAlert(ex, ToastType.ERROR));
  };

  const getSelectedModels = () => {
    getAllModelsForAgentAPI(selectedAgent)
      .then((result) => {
        if (result && result.length) {
          setAgentFiles(result[0].RAG_file);
          const models = result[0].model_API.map((i) => i.modelname);
          updateSelectedModel(models);
        }
      })
      .catch(() => {});
  };

  const updateSelectedModel = (data) => {
    if (modelList.length) {
      let list = modelList.slice();
      list = list.map((item) => {
        if (data.includes(item.id)) {
          item.checked = true;
        }
        return item;
      });
      setModelList(list);
    }
  };

  const renderChooseAgent = () => {
    return (
      <>
        <div className="d-flex gap-2 justify-content-start align-items-end">
          {showMoreInfo ? renderAgentLable() : renderAgentForm()}
        </div>
        {showMoreInfo && renderAgentDesripition()}
      </>
    );
  };
  const renderAgentLable = () => {
    let selectedValue = "";
    if (selectedAgent && agentList.length) {
      selectedValue = agentList.filter((item) => item.id == selectedAgent)[0]
        .agent_filter;
    }
    return (
      <>
        <p className="font-weight-bold p-0">Agent Name:</p>
        <p className="capitalize p-0">{selectedValue}</p>
      </>
    );
  };

  const renderAgentForm = () => {
    return (
      <>
        <div className="col-md-8 px-0" id="wapper">
          <div className="font-weight-bold mb-2">
            Choose Agent <span className="error-msg">*</span>
          </div>
          <div className="choose-agent-dropdown">
            <CustomDropdown
              name=""
              options={agentTypes}
              field="agent_filter"
              value={selectedAgent}
              onChange={(e) => setSelectedAgent(e)}
            />
          </div>
        </div>
        <div className="col-md-4 px-0">{renderCreateAgent()}</div>
      </>
    );
  };

  const onModelChange = (checked, index) => {
    const list = modelList.slice();
    list[index].checked = checked;
    setModelList(list);
  };

  const renderModelList = () => {
    if (modelList && modelList.length) {
      return modelList.map((m, index) => {
        return (
          <AgentModelCheckbox
            key={`agent-model-${index}`}
            item={m}
            agent={selectedAgent}
            onChange={(checked) => onModelChange(checked, index)}
          />
        );
      });
    }
  };

  const renderSelectModels = () => {
    return (
      <>
        <div className="font-weight-bold mt-5 mb-2">
          Assign Models (optional)
        </div>
        <div className="assignmudulesbox row">{renderModelList()}</div>
      </>
    );
  };

  const removeAgentFile = (file) => {
    const payload = {
      id: file.key,
      type: "RAG",
      agent_id: selectedAgent,
    };
    updateLoader(true);
    deleteAgentFileAPI(payload)
      .then(() => {
        const list = agentFiles.filter((item) => item.key !== file.key);
        setAgentFiles(list);
      })
      .catch((e) => {
        showAlert(constant.MESSAGES.COMMON_ERROR_MESSAGE, ToastType.ERROR);
      })
      .finally(() => updateLoader(false));
  };

  const renderAgentFiles = () => {
    if (agentFiles && agentFiles.length) {
      return (
        <>
          <div className="d-flex gap-1 flex-column pl-3">
            {agentFiles.map((file, index) => (
              <div key={`agent-file-${file.key}`}>
                <p className="p-0 form-check-label161 capitalize d-flex justify-content-start gap-1 align-items-center">
                  <FontAwesomeIcon icon={faFileAlt} className="" />{" "}
                  {file.filename}
                  <Link
                    className="error-msg"
                    onClick={() => removeAgentFile(file)}
                  >
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </Link>
                </p>
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      setFileUpload(e.target.files[0]);
    }
  };

  const resetFields = () => {
    setSelectedAgent("");
    resetFile();
  };

  const resetFile = () => {
    setFileUpload(null);
    if (fileRef.current) fileRef.current.value = "";
  };

  const getFileType = (file) => {
    if (file.type.includes("csv")) return FILE_TYPE.CSV[0].toUpperCase();
    if (file.type.includes("pdf")) return FILE_TYPE.PDF[0].toUpperCase();
    if (file.type.includes("text")) return FILE_TYPE.TXT[0].toUpperCase();
  };

  const onFileUpload = (e) => {
    e.preventDefault();
    if (selectedAgent) {
      const formdata = new FormData();
      formdata.append("key", getFileType(fileUpload));
      formdata.append("file", fileUpload);
      formdata.append("agent", selectedAgent);
      uploadAgentFileAPI(formdata)
        .then((res) => {
          console.log(res);
        })
        .catch((ex) => {
          console.log(ex);
        });
    }
  };

  const renderAddSources = () => {
    return (
      <>
        <div className="font-weight-bold mt-5 mb-3">Add Source (optional)</div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="defaultCheck1161"
          />
          <label className="form-check-label161" htmlFor="defaultCheck1161">
            Ask Me Anything
          </label>
        </div>
        {renderAgentFiles()}
        <div>
          <div className="d-flex mt-3 flex-column align-items-start gap-2">
            <form onSubmit={onFileUpload} className="d-flex w-100">
              <div className="fileUpload w-100">
                <input
                  type="file"
                  id="validatedCustomFile"
                  ref={fileRef}
                  accept=".pdf,.csv,.txt"
                  onChange={handleFileChange}
                />
                <span className="bg-white border w-100 px-4 py-2">
                  Select File
                </span>
              </div>
              <button
                className="bg-white ml-2 text-blue font-weight-bold border px-4 py-2"
                type="submit"
              >
                UPLOAD
              </button>
            </form>
            {fileUpload && (
              <div className="d-flex gap-1">
                <p className="p-0">{fileUpload.name}</p>
                <Link className="error-msg" onClick={() => resetFile()}>
                  <FontAwesomeIcon icon={faCircleXmark} />
                </Link>
              </div>
            )}
          </div>
        </div>
        {/* <div className="font-weight-bold mt-5 mb-3">External Source</div>
        <div className="p-3 border d-flex gap-2 flex-wrap">
          <img src={images.zohoIcon} className="w-25" alt="zoho" />
        </div> */}
      </>
    );
  };

  const renderTools = () => {
    return (
      <>
        <div className="font-weight-bold mt-5 mb-3">Tools (optional)</div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id="inlineCheckbox1"
            value="option1"
          />
          <label className="form-check-label" htmlFor="inlineCheckbox1">
            Browsing
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id="inlineCheckbox2"
            value="option2"
          />
          <label className="form-check-label" htmlFor="inlineCheckbox2">
            Code
          </label>
        </div>
      </>
    );
  };

  const addAIAgent = () => {
    const payload = {
      crew_type: selectedAgent,
      active: 1,
    };
    updateLoader(true);
    createAgentAPI(payload)
      .then((r) => {
        showAlert(constant.MESSAGES.GENERAL_SUCCESS, ToastType.SUCCESS);
        resetFields();
        setShowMoreInfo(true);
      })
      .catch(() =>
        showAlert(constant.MESSAGES.COMMON_ERROR_MESSAGE, ToastType.ERROR)
      )
      .finally(() => updateLoader(false));
  };

  const renderCreateAgent = () => {
    const isCreateActive = !selectedAgent;
    if (!showMoreInfo)
      return (
        <button
          className="btn1 text-uppercase font-weight-bold btn-create-agent"
          onClick={() => addAIAgent()}
          disabled={isCreateActive}
        >
          Create agent
        </button>
      );
  };

  const onAgentSelect = (item) => {
    updateActiveAgent(item.id);
    updateShowModal(true);
  };

  const onDeleteAgent = (id, rowIndex) => {
    updateLoader(true);
    deleteAgentAPI(id)
      .then(() => {
        const list = agentList.slice();
        list.splice(1, rowIndex);
        setAgentList(list);
      })
      .catch((e) => {})
      .finally(() => updateLoader(false));
  };

  const renderExistingAgents = () => {
    if (agentList.length)
      return agentList.map((item, index) => {
        return (
          <div
            key={`askme-existing-agent-${index}`}
            className="d-flex justify-content-between"
          >
            <Link onClick={() => onAgentSelect(item)} className="capitalize">
              {item.agent_filter}
            </Link>
            <div className="d-flex justify-content-start gap-2 align-items-center">
              <Link to={`/${constant.PAGES.HOME.AI_CREATE_AGENT}/${item.id}`}>
                <FontAwesomeIcon icon={faPencil} className="" />
              </Link>
              <Link onClick={() => onDeleteAgent(item.id, index)}>
                <FontAwesomeIcon icon={faTrashCan} className="text-danger" />
              </Link>
            </div>
          </div>
        );
      });
  };

  const renderAgentDesripition = () => {
    let selected = [];
    if (selectedAgent && agentList.length) {
      selected = agentList.filter((item) => item.id == selectedAgent);
    }
    if (selected.length) {
      return (
        <>
          <div className="font-weight-bold mt-3">What this agent do:</div>
          <div>{selected[0].desription}</div>
        </>
      );
    }
  };

  const render = () => {
    return (
      <>
        {isLoading && <CusomtLoader full />}
        <div className="row m-0">
          <div className="col-md-9 p-0">
            <div className="py-5 px-3 h-100">
              <div className="col-md-10 mx-auto">
                {renderChooseAgent()}
                {showMoreInfo && renderSelectModels()}
                {showMoreInfo && renderAddSources()}
                {showMoreInfo && renderTools()}
              </div>
            </div>
          </div>
          <div className="col-md-3 p-0">
            <div className="py-5 px-3 h-100 bg-white">
              <div className="d-flex gap-2 flex-column">
                <h4>Exsiting Agents</h4>
                {renderExistingAgents()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return render();
};

export default CreateAgent;
