import React, { createContext, useContext } from "react";
import * as ACTION from "./../reducers/types";
import useAIAskMeReducer from "../reducers/ai-ask-me.reducer";
import { getChatboxQuestion } from "../service/ai-model.service";
import { useAppContext } from "./app.context";
import { useAuthContext } from "./auth.context";
import constant, { GroupByType, ToastType } from "../helper/constant";
import { groupByPropertyOfArray } from "../helper/helper";

const AIAskMeContext = createContext();

const AIAskMeProvider = ({ children }) => {
  const { state, dispatch } = useAIAskMeReducer();
  const { updateLoader, showAlert } = useAppContext();

  const getQueryHistory = () => {
    updateLoader(true);
    getChatboxQuestion()
      .then((response) => {
        if (response && response.result) {
          const grouplist = groupByPropertyOfArray(
            response.result,
            GroupByType.agent
          );
          dispatch({
            type: ACTION.UPDATE_QUERY_HISTORY,
            payload: response.result,
          });
          dispatch({
            type: ACTION.UPDATE_GROUP_BY_QUERY_HISTORY,
            payload: grouplist,
          });
        }
      })
      .finally(() => updateLoader(false));
  };

  const updateAskMeGroupBy = (property) => {
    const list = state.queryHistory;
    const grouplist = groupByPropertyOfArray(list, property);
    dispatch({
      type: ACTION.UPDATE_GROUP_BY_QUERY_HISTORY,
      payload: grouplist,
    });
  };

  const updateCurrentQuery = (payload) =>
    dispatch({ type: ACTION.UPDATE_CURRENT_QUERY, payload });

  const updateActiveAgent = (payload) =>
    dispatch({ type: ACTION.UPDATE_ACTIVE_AGENT, payload });

  const updateShowModal = (payload) =>
    dispatch({ type: ACTION.UPDATE_SHOW_MODAL, payload });

  const updateAskMeSelectedModels = (payload) =>
    dispatch({ type: ACTION.UPDATE_ASK_ME_SELECTED_MODEL, payload });

  const updateQueryTasks = (payload) =>
    dispatch({ type: ACTION.UPDATE_CURRENT_QUERY_TASKS, payload });

  const updateCurrentQueryUUID = (payload) =>
    dispatch({ type: ACTION.UPDATE_ACTIVE_UUID, payload });

  const clearAskMe = () => dispatch({ type: ACTION.CLEAR_ASK_ME });

  const handleError = (ex) => {
    showAlert(
      ex?.message || constant.MESSAGES.COMMON_ERROR_MESSAGE,
      ToastType.ERROR
    );
  };

  return (
    <AIAskMeContext.Provider
      value={{
        ...state,
        getQueryHistory,
        updateCurrentQuery,
        updateAskMeGroupBy,
        updateShowModal,
        updateQueryTasks,
        updateAskMeSelectedModels,
        updateActiveAgent,
        updateCurrentQueryUUID,
        clearAskMe,
      }}
    >
      {children}
    </AIAskMeContext.Provider>
  );
};

export const useAIAskMeContext = () => useContext(AIAskMeContext);

export { AIAskMeProvider, AIAskMeContext };
