import React, { useEffect, useState } from "react";
import CustomLoader from "./../../common/CustomLoader";
import { useAIAskMeContext } from "../../../context/ai-ask-me.context";
import { useAppContext } from "../../../context/app.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { GroupByType } from "../../../helper/constant";
import { ageAgo } from "../../../helper/helper";

const ChatbotHistory = () => {
  const { isLoading } = useAppContext();
  const {
    getQueryHistory,
    updateAskMeGroupBy,
    queryHistoryByGroup,
    showModal,
    updateCurrentQueryUUID,
    updateCurrentQuery,
    updateActiveAgent,
  } = useAIAskMeContext();
  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (showModal) {
      getQueryHistory();
    }
  }, [showModal]);

  useEffect(() => {
    const key = status ? GroupByType.agent : GroupByType.date;
    updateAskMeGroupBy(key);
  }, [status]);

  const renderGroupKey = (key) => {
    return status ? `Agent: ${key}` : ageAgo(key);
  };

  const renderHistory = () => {
    if (queryHistoryByGroup && queryHistoryByGroup.length)
      return queryHistoryByGroup.map((group, groupKey) => {
        return (
          <div key={`group-${groupKey}`}>
            {group.key !== "undefined" && (
              <p className="text-secondary p-0 capitalize">
                {renderGroupKey(group.key)}
              </p>
            )}
            {group.value.map((item, key) => (
              <div
                key={`query-${key}`}
                onClick={() => {
                  updateCurrentQueryUUID(item.uuid);
                  updateCurrentQuery(null);
                  updateActiveAgent(item.agent_id);
                }}
                className="py-2 text-white cursor-pointer d-flex align-items-center gap-1"
              >
                <FontAwesomeIcon icon={faComment} />
                <div>{item.user_input}</div>
              </div>
            ))}
          </div>
        );
      });
    return <p>No data available</p>;
  };

  const renderGroupBySwich = () => {
    return (
      <div className="checkbox-wrapper-64 d-flex">
        <label className="switch m-0">
          <input
            type="checkbox"
            checked={status}
            onChange={() => setStatus(!status)}
          />
          <span className="slider"></span>
        </label>
      </div>
    );
  };

  const render = () => {
    return (
      <div className="chatbotsidebar position-relative px-3 py-4">
        <h3 className="h6 font-weight-bold mt-4 text-white">History</h3>
        <div className="font-14 text-secondary mt=y-3">Recent Queries</div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="p-0">
            Group By:{" "}
            <span className="font-weight-bold">
              {status ? "Agent" : "Date"}
            </span>
          </p>{" "}
          {renderGroupBySwich()}
        </div>
        <div className="mx-h-65 d-flex flex-column gap-2">
          {!isLoading && renderHistory()}
        </div>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <CustomLoader />
          </div>
        )}
      </div>
    );
  };
  return render();
};

export default ChatbotHistory;
