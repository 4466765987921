import React, { useState } from "react";
import { connectModelToAgentAPI } from "../../../service/ai-model.service";
import { ToastType } from "../../../helper/constant";
import CusomtLoader from "./../../common/CustomLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmarksLines,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

const AgentModelCheckbox = ({ item, agent, onChange }) => {
  const { id, checked, template_name } = item;
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onModelChange = (value) => {
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append("key", "API");
    formdata.append("id", item.id);
    formdata.append("agent", agent);
    formdata.append("isAdd ", value);
    connectModelToAgentAPI(formdata)
      .then((r) => {
        if (r) {
          setStatus(ToastType.SUCCESS);
        } else {
          setStatus(ToastType.ERROR);
        }
      })
      .catch((ex) => {
        setStatus(ToastType.ERROR);
      })
      .finally(() => setIsLoading(false));
    onChange && onChange();
  };

  const renderLoader = () => {
    if (isLoading) {
      return <CusomtLoader size="sm" />;
    }
    if (status === ToastType.ERROR) {
      return <FontAwesomeIcon icon={faXmarksLines} className="text-danger" />;
    }
    if (status === ToastType.SUCCESS) {
      return <FontAwesomeIcon icon={faCircleCheck} className="text-success" />;
    }
  };

  const render = () => {
    return (
      <div className="col-md-6 d-flex align-items-center gap-2" key={id}>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={checked}
            onChange={(e) => onModelChange(e.target.checked)}
            id={`defaultCheck-${id}`}
          />
          <label
            className="form-check-label capitalize"
            htmlFor={`defaultCheck-${id}`}
          >
            {template_name}
          </label>
        </div>
        {renderLoader()}
      </div>
    );
  };

  return render();
};

export default AgentModelCheckbox;
