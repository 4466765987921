import React from "react";
import images from "../../helper/images";
import constant from "../../helper/constant";
import { useAuthContext } from "../../context/auth.context";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { getInitials } from "../../helper/helper";
import AskMeAnything from "./AskmeAnything/AskMeAnything";
import { AIAskMeProvider } from "../../context/ai-ask-me.context";
import { useScreenSizeContext } from "../../context/mobile-layout.context";

const Header = () => {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userInfo } = useAuthContext();
  const route = useLocation();
  const { isMobileScreen } = useScreenSizeContext();

  const onLogout = () => {
    logout();
    navigate(`/`);
  };
  const renderRole = () => {
    const {
      org: { org_name, role },
    } = userInfo;
    return (
      <div className="d-flex flex-column">
        <p className="p-0 capitalize">
          Organization : <span>{org_name}</span>{" "}
        </p>
        {role && (
          <p className="p-0 capitalize">
            Role: <span>{role}</span>
          </p>
        )}
      </div>
    );
  };

  const renderAppName = () => {
    if (isLoggedIn) {
      const {
        org: { org_name },
      } = userInfo;
      const initials = getInitials(org_name);
      return (
        <>
          <div className="profile dropdown d-flex gap-3 align-items-baseline">
            {!isMobileScreen && renderRole()}
            <div
              className="cursor-pointer"
              id="btnProfileMenu"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="avatar-circle">
                <div className="avatar-initials">{initials}</div>
              </div>
            </div>
            <div
              className="dropdown-menu font-14 py-0"
              aria-labelledby="btnProfileMenu"
            >
              <Link
                className="dropdown-item border-bottom"
                to={`/${constant.PAGES.HOME.USER_MANAGEMENT}`}
              >
                <span className="mr-2">
                  <svg
                    width="20"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 20H22V18C22 16.3431 20.6569 15 19 15C18.0444 15 17.1931 15.4468 16.6438 16.1429M17 20H7M17 20V18C17 17.3438 16.8736 16.717 16.6438 16.1429M7 20H2V18C2 16.3431 3.34315 15 5 15C5.95561 15 6.80686 15.4468 7.35625 16.1429M7 20V18C7 17.3438 7.12642 16.717 7.35625 16.1429M7.35625 16.1429C8.0935 14.301 9.89482 13 12 13C14.1052 13 15.9065 14.301 16.6438 16.1429M15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7ZM21 10C21 11.1046 20.1046 12 19 12C17.8954 12 17 11.1046 17 10C17 8.89543 17.8954 8 19 8C20.1046 8 21 8.89543 21 10ZM7 10C7 11.1046 6.10457 12 5 12C3.89543 12 3 11.1046 3 10C3 8.89543 3.89543 8 5 8C6.10457 8 7 8.89543 7 10Z"
                      stroke="#545F71"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Manage Users
              </Link>
              <Link
                className="dropdown-item border-bottom"
                to={`/${constant.PAGES.HOME.PROFILE}`}
              >
                <span className="mr-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.12104 17.8037C7.15267 16.6554 9.4998 16 12 16C14.5002 16 16.8473 16.6554 18.879 17.8037M15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                      stroke="#545F71"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Profile
              </Link>
              <Link className="dropdown-item" onClick={onLogout}>
                <span className="mr-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H15M8 8L4 12M4 12L8 16M4 12L16 12"
                      stroke="#000000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>{" "}
                Logout
              </Link>
            </div>
          </div>
        </>
      );
    }
    return (
      <div className="d-flex align-items-center">
        {!route.pathname.includes("login") && (
          <Link to="/login" className="mr-2 graddientbtn">
            Login
          </Link>
        )}
      </div>
    );
  };

  const renderAskMeAnything = () => {
    if (isLoggedIn) {
      const {
        org: { askme },
      } = userInfo;
      if (askme) return <AskMeAnything />;
    }
  };

  return (
    <header className="dahboardheader bg-white border-bottom px-3 py-2 d-flex align-items-center justify-content-between">
      <NavLink
        to="/"
        className="d-flex justify-content-center align-items-center"
      >
        <img src={images.incorpLogo} className="header-logo" alt="Avtar AI" />
        <h4 className="m-0 p-0 font-weight-bold">{constant.AppTitle}</h4>
      </NavLink>
      <div className="d-flex flex-row gap-2 align-items-center">
        {!isMobileScreen && renderAskMeAnything()}
        {renderAppName()}
      </div>
    </header>
  );
};

export default Header;
