import React from "react";
import ChatbotResult from "./ChatbotResult";
import { useAIAskMeContext } from "../../../context/ai-ask-me.context";
import { Link } from "react-router-dom";
import constant from "./../../../helper/constant";

const AskMeAnything = () => {
  const { showModal, updateShowModal, updateActiveAgent } = useAIAskMeContext();

  const onAskMeAnthingPressed = () => {
    updateShowModal(!showModal);
    updateActiveAgent(constant.defaultAgent);
  };

  const renderChatHeader = () => {
    return (
      <Link
        className="askmebtn d-flex align-items-center mr-4 cursor-pointer"
        onClick={() => onAskMeAnthingPressed()}
      >
        <div>
          <svg width="45" height="45" viewBox="0 0 49 49" fill="none">
            <path
              className="qstnbgclr"
              d="M49 24.5C49 38.031 38.031 49 24.5 49C10.969 49 0 38.031 0 24.5C0 10.969 10.969 0 24.5 0C38.031 0 49 10.969 49 24.5Z"
            />
            <path
              className="qstnclr"
              d="M21.8638 30.2783V29.7953C21.8732 28.1381 22.02 26.8171 22.3041 25.8323C22.5977 24.8474 23.0238 24.052 23.5825 23.4459C24.1412 22.8398 24.8136 22.2906 25.5996 21.7982C26.1867 21.4194 26.7123 21.0264 27.1763 20.6192C27.6403 20.212 28.0096 19.7622 28.2842 19.2698C28.5588 18.7679 28.6962 18.2091 28.6962 17.5936C28.6962 16.9402 28.5399 16.3673 28.2274 15.8749C27.9149 15.3824 27.4935 15.0037 26.9632 14.7385C26.4424 14.4734 25.8647 14.3408 25.2302 14.3408C24.6147 14.3408 24.0323 14.4781 23.4831 14.7527C22.9338 15.0179 22.484 15.4156 22.1337 15.9459C21.7833 16.4667 21.5939 17.1154 21.5655 17.8919H15.77C15.8174 15.998 16.2719 14.4355 17.1337 13.2044C17.9954 11.9639 19.1365 11.0406 20.557 10.4345C21.9774 9.819 23.5446 9.51123 25.2587 9.51123C27.1431 9.51123 28.8098 9.82373 30.2587 10.4487C31.7075 11.0643 32.8439 11.9591 33.6677 13.1334C34.4916 14.3076 34.9035 15.7234 34.9035 17.3805C34.9035 18.4885 34.7189 19.4734 34.3496 20.3351C33.9897 21.1874 33.4831 21.9449 32.8297 22.6078C32.1763 23.2612 31.4045 23.8531 30.5143 24.3834C29.7662 24.8285 29.1507 25.2925 28.6677 25.7754C28.1943 26.2584 27.8391 26.8171 27.6024 27.4516C27.3751 28.086 27.2568 28.8673 27.2473 29.7953V30.2783H21.8638ZM24.6763 39.3692C23.7293 39.3692 22.9196 39.0377 22.2473 38.3749C21.5844 37.7025 21.2577 36.8976 21.2672 35.9601C21.2577 35.0321 21.5844 34.2366 22.2473 33.5737C22.9196 32.9109 23.7293 32.5794 24.6763 32.5794C25.5759 32.5794 26.3666 32.9109 27.0484 33.5737C27.7302 34.2366 28.0759 35.0321 28.0854 35.9601C28.0759 36.5851 27.9102 37.158 27.5882 37.6788C27.2757 38.1902 26.8638 38.6021 26.3524 38.9146C25.841 39.2177 25.2823 39.3692 24.6763 39.3692Z"
            />
          </svg>
        </div>
        <span>
          Ask me <b>Anything</b>
        </span>
      </Link>
    );
  };

  const renderChatboxResult = () => {
    return (
      <section
        className={`chatbotanswersec ${showModal ? "showchatboxoptions" : ""}`}
      >
        <ChatbotResult />
      </section>
    );
  };

  const render = () => {
    return (
      <>
        {renderChatHeader()}
        {renderChatboxResult()}
      </>
    );
  };
  return render();
};

export default AskMeAnything;
