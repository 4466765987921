import React, { useEffect, useState } from "react";
import ChatbotHistory from "./ChatbotHistory";
import ChatQueryResult from "./ChatQueryResult";
import { useAIAskMeContext } from "../../../context/ai-ask-me.context";
import ChatbotFiles from "./ChatbotFiles";
import images from "../../../helper/images";
import constants from "../../../helper/constant";
import { Link } from "react-router-dom";
import { getAgentsAPI } from "../../../service/ai-model.service";

const ChatbotResult = () => {
  const {
    updateCurrentQuery,
    currentQuery,
    showModal,
    updateShowModal,
    clearAskMe,
    updateActiveAgent,
    activeAgent,
    updateCurrentQueryUUID,
  } = useAIAskMeContext();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [force, setForce] = useState(false);
  const [leftOpen, setLeftOpen] = useState(false);
  const [rightOpen, setRightOpen] = useState(false);
  const [toggleAgents, setToggleAgents] = useState(false);
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    setQuery(currentQuery);
  }, [currentQuery, force]);

  useEffect(() => {
    if (showModal) {
      getAgentsAPI().then((result) => {
        updateActiveAgent(activeAgent);
        setAgents(result);
      });
    } else {
      updateCurrentQuery(null);
      updateCurrentQueryUUID(null);
    }
  }, [showModal]);

  const onQuerySubmit = (e) => {
    e.preventDefault();
    setQuery(query.slice());
    requestAnswer();
  };

  const requestAnswer = () => {
    if (query !== currentQuery) {
      updateCurrentQuery(query);
    } else {
      setForce(!force);
    }
  };

  const renderCloseModal = () => {
    return (
      <div
        className="crossdismiss"
        onClick={() => {
          updateShowModal(false);
          clearAskMe();
        }}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 30L30 10M10 10L30 30"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };

  const renderSearchForm = () => {
    return (
      <div className="chatgptbox bg-transpatrent p-1 border-bottom-grey">
        <form onSubmit={onQuerySubmit}>
          <div className="d-flex align-items-center">
            <div className=" py-2 w-100 justify-content-between d-flex align-items-center">
              <input
                onChange={(e) => setQuery(e.target.value.slice())}
                value={query}
                type="text"
                className="askmeanything bg-none text-white border-0 w-75"
                placeholder="Ask Me Anything..."
              />
              <div className="d-flex align-items-center">
                <small className="powered-by">
                  <i>Powered by ChatGPT</i>
                </small>
              </div>
            </div>
            <div className="cursor-pointer ml-2">
              <svg
                width="34"
                height="35"
                viewBox="0 0 39 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.2193 3.83988L19.2664 3.83753L19.3123 3.82642C19.3856 3.80864 19.4606 3.7994 19.5358 3.79883C22.8074 3.79977 25.5 6.56518 25.5 10.0005V20.0005C25.5 23.413 22.7968 26.1672 19.5 26.1672C16.2033 26.1672 13.5 23.413 13.5 20.0005V10.0008C13.5021 8.41213 14.0997 6.88729 15.1656 5.74127C16.2312 4.59557 17.6829 3.91628 19.2193 3.83988Z"
                  stroke="#abababbf"
                ></path>
                <path
                  d="M18.375 33.2183V32.7786L17.9389 32.7224C11.9502 31.9506 7.24628 26.8137 7.00937 20.5H9.26148C9.51682 26.049 13.9992 30.5 19.5 30.5C25.0008 30.5 29.4832 26.049 29.7385 20.5H31.9906C31.7538 26.8151 27.0499 31.9505 21.0611 32.7224L20.625 32.7786V33.2183V36.1667H18.375V33.2183Z"
                  stroke="#abababbf"
                ></path>
              </svg>
            </div>

            <Link className="ml-3" onClick={() => requestAnswer()}>
              <svg
                height="28"
                fill="#fff"
                className="cursor-pointer"
                viewBox="-15 0 512 512"
                width="28"
                xmlns="http://www.w3.org/2000/svg"
                id="fi_1477102"
              >
                <path d="m482 241-482-241 70.605469 241zm0 0"></path>
                <path d="m0 512 482-241h-411.394531zm0 0"></path>
              </svg>
            </Link>
          </div>
        </form>
      </div>
    );
  };

  const renderAskmeLeftPanel = () => {
    return (
      <div className={`leftbar3 px-0 ${leftOpen ? "active" : ""}`}>
        <div className="chtbothstrybox text-white py-3">
          <ChatbotFiles />
        </div>
        <Link
          className={`iconclic4 ${leftOpen ? "iconactive3" : ""}`}
          onClick={() => setLeftOpen(!leftOpen)}
        >
          <img src={images.sidepanelArrow} alt="slide" />
        </Link>
      </div>
    );
  };

  const renderAskmeRightPanel = () => {
    return (
      <div className={`rightbar3 px-0 ${rightOpen ? "active" : ""}`}>
        <div className="chtbothstrybox text-white py-3">
          <ChatbotHistory />
        </div>
        <Link
          className={`iconclic4 ${rightOpen ? "" : "iconactive3"}`}
          onClick={() => setRightOpen(!rightOpen)}
        >
          <img src={images.sidepanelArrow} alt="slide" />
        </Link>
      </div>
    );
  };

  const renderAgentList = () => {
    if (agents && agents.length) {
      return agents.map((item, index) => {
        return (
          <Link
            key={`agent-${index}`}
            className="dropdown-item capitalize"
            onClick={() => {
              updateActiveAgent(item.id);
              setToggleAgents(false);
            }}
          >
            {item.agent_filter}
          </Link>
        );
      });
    }
  };

  const renderAgentsDropdown = () => {
    let agentName = "";
    if (activeAgent && agents && agents.length) {
      const list = agents.filter((item) => item.id === activeAgent);
      if (list.length) {
        agentName = list[0].agent_filter;
      }
    }

    return (
      <div className="serchrghtselect">
        <div className="custom-dropdown">
          <button
            className="dropdown-toggle capitalize"
            onClick={() => setToggleAgents(!toggleAgents)}
          >
            Agent: <b>{activeAgent ? agentName : "--"}</b>
          </button>
          <div
            className="dropdown-menu"
            style={{ display: toggleAgents ? "block" : "none" }}
          >
            {renderAgentList()}
            <div className="dropdown-divider"></div>
            <Link
              to={`/${constants.PAGES.HOME.AI_CREATE_AGENT}`}
              onClick={() => {
                setToggleAgents(false);
                updateShowModal(false);
              }}
              className="dropdown-item"
            >
              + Add Agent
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const renderAskmeMainContent = () => {
    return (
      <div className="row">
        <div className="col-md-12 position-relative px-0">
          <div className="chtbotmidsec text-white mt-3">
            <div className="px-5">
              <div className="row align-items-center chat-search-bar">
                <div className="col-md-9">{renderSearchForm()}</div>
                <div className="col pl-2">{renderAgentsDropdown()}</div>
              </div>
              <div className="chtbot-query-details">
                <ChatQueryResult />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAskMeAnythingContent = () => {
    return (
      <section
        className={`chatbotanswersec3 ${leftOpen ? "active-left" : ""} ${rightOpen ? "active-right" : ""}`}
      >
        <div className="container-fluid h-100">
          {renderAskmeLeftPanel()}
          {renderAskmeMainContent()}
          {renderAskmeRightPanel()}
        </div>
      </section>
    );
  };

  const render = () => {
    return (
      <>
        <div className="container-fluid">
          <div className="row">{renderCloseModal()}</div>
          {renderAskMeAnythingContent()}
        </div>
      </>
    );
  };
  return render();
};

export default ChatbotResult;
