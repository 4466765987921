import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAIModelContext } from "../../../context/ai-model.context";
import constant from "../../../helper/constant";
import {
  calculatePercentage,
  formatToCurrency,
  getTotalOfProperty,
} from "../../../helper/helper";
import ModelCreator from "../ModelCreator";
import PredDonutChart from "../../common/charts/PredDonutChart";

const AIValue = () => {
  const { getAIValue, models, aiValue } = useAIModelContext();
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params.id) {
      getAIValue(params.id, (data) => {});
    }
  }, [params.id]);

  const onShowTimeLine = () => {
    navigate(`/${constant.PAGES.HOME.AI_ROADMAP}/${params.id}`);
  };

  const renderDonutChart = () => {
    const report = getRevenueReport();
    return <PredDonutChart data={report} center={renderCenter} />;
  };

  const getRevenueReport = () => {
    if (models.length) {
      const tasks = models.flatMap((i) => i.tasks);
      return tasks.map((task) => {
        const { name, aiBenefit } = task;
        return {
          name,
          value: aiBenefit,
        };
      });
    }
    return [];
  };

  const renderCenter = (cx, cy) => {
    if (models.length) {
      const tasks = models.flatMap((i) => i.tasks);
      const aiProfit = getTotalOfProperty(tasks, "aiBenefit");
      const aiSalaryCost = getTotalOfProperty(tasks, "salaryCost");
      const percentage = calculatePercentage(aiProfit, aiSalaryCost).toFixed(
        2.2
      );
      return (
        <>
          <text
            x={cx}
            y={cy - 25}
            dy={8}
            textAnchor="middle"
            fill="#000000"
            fontSize={24}
            fontWeight="bold"
          >
            <tspan>Additional Profit*</tspan>
          </text>
          <text
            x={cx}
            y={cy + 10}
            dy={8}
            textAnchor="middle"
            fill="#217CE8"
            fontSize={28}
            fontWeight="bold"
          >
            {formatToCurrency(aiProfit)}
          </text>
          <text
            x={cx}
            y={cy + 35}
            dy={8}
            textAnchor="middle"
            fill="#217CE8"
            fontSize={18}
            fontWeight="bold"
          >
            {`(${percentage}%)`}
          </text>
        </>
      );
    }
  };

  const renderModelProfitRow = () => {
    if (models && models.length) {
      const taskList = models.flatMap((item) => item.tasks);
      return taskList.map((task) => {
        const { name, model_name, salaryCost, aiBenefit, aiCost } = task;
        return (
          <tr>
            <td className="border-right border-bottom text-left">{name}</td>
            <td className="border-right border-bottom text-left">
              {model_name}
            </td>
            <td className="border-right border-bottom ">
              {formatToCurrency(salaryCost)}
            </td>
            <td className="border-right border-bottom">
              {formatToCurrency(aiBenefit)}
            </td>
            <td className="border-right border-bottom">
              {formatToCurrency(aiCost)}
            </td>
            <td className="border-right border-bottom">
              {formatToCurrency(aiBenefit - aiCost)} (
              {calculatePercentage(aiBenefit - aiCost, salaryCost).toFixed(2.2)}
              %)
            </td>
          </tr>
        );
      });
    }
  };

  const renderAIValueSummary = () => {
    if (aiValue)
      return (
        <section className="revenuetblsec">
          <div className="container">
            <table className="table table-borderless border text-center revenuetbl ai-value-summary-tbl">
              <thead>
                <tr className="border-bottom">
                  <th scope="col" className="border-right">
                    3-year ROI
                  </th>
                  <th scope="col" className="border-right">
                    3 Months Cost of delay
                  </th>
                  <th scope="col" className="border-right">
                    Payback period
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-right border-bottom">
                    {Number(aiValue.roi).toFixed(2.2)}%
                  </td>
                  <td className="border-right border-bottom">
                    {formatToCurrency(aiValue.threeMonthDelayCost)}
                  </td>
                  <td className="border-right border-bottom">
                    {Math.ceil(aiValue.paybackPeriod)} Months
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      );
  };
  const renderModelWiseProfitTable = () => {
    return (
      <section className="pb-5 revenuetblsec">
        <div className="container">
          <table className="table table-borderless border text-center revenuetbl">
            <thead>
              <tr className="border-bottom">
                <th scope="col" className="border-right">
                  Task
                </th>
                <th scope="col" className="border-right">
                  Model
                </th>
                <th scope="col" className="border-right">
                  Cost Before AI <br />
                  (A)
                </th>
                <th scope="col" className="border-right">
                  Value added by AI
                  <br />
                  (B)
                </th>
                <th scope="col" className="border-right">
                  AI Investment <br />
                  (C)
                </th>
                <th scope="col" className="">
                  EBIT added by AI (%) <br />
                  (D = B - C)
                </th>
              </tr>
            </thead>
            <tbody>{renderModelProfitRow()}</tbody>
          </table>
        </div>
      </section>
    );
  };

  const render = () => {
    if (models && models.length) {
      return (
        <div className="row mx-0">
          <div className="col-md-12 px-md-0">
            <div className="p-3 pb-5">
              <div className="text-center">
                <ModelCreator />
                <div className="p-3 pt-5 d-flex flex-column gap-2">
                  <h3>AI Value summary</h3>
                  <div className="col-sm-6 mx-auto text-center">
                    {renderAIValueSummary()}
                  </div>
                  <div className="col-sm-12">
                    {renderModelWiseProfitTable()}
                  </div>
                  <div className="col-sm-12">{renderDonutChart()}</div>
                </div>
                <button
                  className="btn1 btn radious-0  w-30 mt-2 f-13 text-uppercase my-4 py-2 font15fontweight600"
                  onClick={onShowTimeLine}
                >
                  Timeline to execute AI Models
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return render();
};

export default AIValue;
